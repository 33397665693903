import { useState, useEffect } from "react";
import { BoxStepDesktop, BoxStepMobile, CenteredBox, CenteredBoxMobile } from "../../../styles/themes/components/box";
import { V3Button, V3RadioButton } from "../../../styles/themes/components/button";
import { V3TextCustom } from "../../../styles/themes/components/input";
import { V3TextH1 } from "../../../styles/themes/components/texts";
import api from "../../../services/api";
import { V3Modal } from "../../../styles/themes/components/modal";
import {  V3Searcheable } from "../../../components/Selects";
import moment from "moment";
import { verifyDate } from "..";
import { administradorasList } from "./data.content";
import { validNome, validRazaoSocial, validateEmail } from "../../../utils/validate";
import { v3CellphoneFormater, v3FormataCNPJ, v3MaskCPF } from "../../../styles/themes/utils/format";
import { v3ValidaCpfCnpj, v3ValidateEmail } from "../../../styles/themes/utils/validate";
import { Checkbox, FormControlLabel, Radio } from "@mui/material";
import { v3Colors } from "../../../styles/themes/v3/colors";
import { getURLParameter } from "../../../utils/sanitize";

export const Step1 = (props) => {

  const { } = props;
  const [admSelect, setAdmSelect] = useState(administradorasList);
  const [adm, setAdm] = useState("");
  const [admDesc, setAdmDesc] = useState("");
  const [recInitial, setRecInitial] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");

  const [tipoPessoa, setTipoPessoa] = useState("PF");
  const [cpf, setCpf] = useState("");
  const [nomeCompleto, setNomeCompleto] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [isWhatsApp, setIsWhatsApp] = useState("");

  const [cnpj, setCnpj] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [nomeCompletoErr, setNomeCompletoErr] = useState(false);

  const [cpfErr, setCpfErr] = useState(false);
  const [cnpjErr, setCnpjErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [telefoneErr, setTelefoneErr] = useState(false);
  const [razaoErr, setRazaoErr] = useState("");

  const [origem, setOrigem] = useState("");
  const [midia, setMidia] = useState("");
  const [campanha, setCampanha] = useState("");
  const [segmento, setSegmento] = useState("");
  const [anuncio, setAnuncio] = useState("");

  const [mobile] = useState(window.matchMedia("(max-width:768px)").matches);

  function fillCPF(cpf) {
    const onlyDigits = cpf?.replace(/[^\d]/g, "");
    return onlyDigits?.padStart(11, "0");
  }

  function fillCNPJ(cnpj) {
    const onlyDigits = cnpj?.replace(/[^\d]/g, "");
    return onlyDigits?.padStart(14, "0");
  }

  const [startForm, setStartForm] = useState("");

  function handleRazaoSocial(t) {
    const valid = validRazaoSocial(t);
    if ((razaoSocial && valid) || razaoSocial === "") {
      setRazaoSocial(t);
      setRazaoErr(false);
    } else {
      setRazaoSocial(t);
      setRazaoErr(true);
    }
  }

  function handleCpf(text) {
    const validating = text.replace(/[^\d]/g, "");
    if (validating.length <= 11) {
      const cpfs = v3MaskCPF(validating);
      setCpf(cpfs);

      if (v3ValidaCpfCnpj(cpfs) || text === "") {
        setCpfErr(false);
      } else {
        setCpfErr(true);
      }
    }
  }

  function handleCnpj(text) {
    const validating = text.replace(/[^\d]/g, "");
    if (validating.length <= 14) {
      const cn = v3FormataCNPJ(validating);
      setCnpj(cn);

      if (v3ValidaCpfCnpj(cn) || text === "") {
        setCnpjErr(false);
      } else {
        setCnpjErr(true);
      }
    }
  }

  function handleEmail(t) {
    const text = t?.replaceAll(" ", "");
    if (v3ValidateEmail(text) || text === "") {
      setEmail(text);
      setEmailErr(false);
    } else {
      setEmail(text);
      setEmailErr(true);
    }
  }

  function handleNomeCompleto(text) {
    const valid = validNome(text);

    if (!text || !valid) {
      setNomeCompleto(text);
      setNomeCompletoErr(true);
    } else {
      setNomeCompleto(text);
      setNomeCompletoErr(false);
    }
  }

  function handleTelefone(t) {
    const validatePhone = t.replace(/[^0-9]/g, "").split("");
    if (t) {
      setTelefoneErr(false);
      if (t.length > 15) {
        setTelefone(telefone);
      } else {
        setTelefone(v3CellphoneFormater(t));
      }
    }
    if (!t || t == "" || t == "(") {
      setTelefoneErr(true);
      setTelefone(t);
    }

    if (t.length < 14 || validatePhone[0] === "0") {
      setTelefoneErr(true);
    } else {
      setTelefoneErr(false);
    }
  }

  async function handleNext(e) {
    e.preventDefault();

    var validacao = validarDados();
    if(validacao !== "ok"){
        setHasError(true);
        return setErrorText(validacao);
    }

    const docPF = parseInt(cpf?.replace(/[^0-9]/g, ""));
    const docPJ = parseInt(cnpj?.replace(/[^0-9]/g, ""));

    const formData = new FormData();
    formData.append("administratorId", adm);
    formData.append("contactAllowed", isWhatsApp);
    formData.append("document", tipoPessoa == "PF" ? docPF : docPJ);
    formData.append("name", tipoPessoa == "PF" ? nomeCompleto : razaoSocial);
    formData.append("email", email);
    formData.append("phone", telefone);
    formData.append("utmSource", origem);
    formData.append("utmMedium", midia);
    formData.append("utmCampaign", campanha);
    formData.append("utmTerm", segmento);
    formData.append("utmContent", anuncio);
    formData.append("origin", 10);

    const config = { headers: { "content-type": "multipart/form-data" } };
    await api
      .post("/api/campanha-lead/1.0/lead", formData)
      .then(async ({}) => {
        window.location.href = "/vc-sucesso";
      }).catch(() => {
        setErrorText("Houve um erro ao salvar os dados");
        setHasError(true);
      })
  }

  useEffect(() => {

    verifyDate();
    const origemUTM = getURLParameter('utm_source');
    const midiaUTM = getURLParameter('utm_medium');
    const campanhaUTM = getURLParameter('utm_campaign');
    const segmentoUTM = getURLParameter('utm_term');
    const anuncioUTM = getURLParameter('utm_content');
    if(!origem) {setOrigem(origemUTM)};
    if(!midia) {setMidia(midiaUTM)};
    if(!campanha) {setCampanha(campanhaUTM)};
    if(!segmento) {setSegmento(segmentoUTM)};
    if(!anuncio) {setAnuncio(anuncioUTM)};

  }, []);

  function validarDados(){
    if (!adm) {
        return "Por favor selecione Administradora.";
    }

    if (tipoPessoa === "PF") {
        if (!nomeCompleto || nomeCompletoErr) {
          setNomeCompletoErr(true);
          return "Preencha o nome completo";
        }
  
        if (!cpf || !v3ValidaCpfCnpj(cpf) || cpfErr) {
          setCpfErr(true);
          return "Preencha o CPF corretamente.";
        }
    }

    if (tipoPessoa === "PJ") {
        if (!razaoSocial || razaoErr) {
          setRazaoErr(true);
          return "Preencha a Razão Social corretamente.";
        }
  
        if (!cnpj || !v3ValidaCpfCnpj(cnpj) || cnpjErr) {
          setCnpjErr(true);
          return "Preencha o CNPJ corretamente.";
        }
    }

    if (!email || !validateEmail(email) || emailErr) {
        setEmailErr(true);
        return "Preencha o e-mail corretamente.";
    }
    if (!telefone || telefoneErr) {
        
        setTelefoneErr(true);
        return "Preencha o telefone corretamente.";
    }

    return "ok";
  }

  function handleChangeSelectAdm(e) {
    if (!e) {
      setAdm(null);
      setAdmDesc("");
    } else {
      const { id, label } = e;
      localStorage.setItem("administradoraO", `${id}`);
      localStorage.setItem("administradoraODesc", label);
      if (id == "125")
        localStorage.setItem(
          "canopusAceite",
          moment([], true).format("DD/MM/YYYY  HH:mm:ss")
        );
      setAdm(`${id}`);
      setRecInitial(null);

      setAdmDesc(label);
    }
  }

  return (
    <>
    {!mobile && (
        <>
        <V3Modal
            open={hasError}
            actionClose={setHasError}
            title={"Atenção!"}
            text={errorText}
            type="error"
        />
        <V3Modal
            open={hasSuccess}
            actionClose={setHasSuccess}
            title={"Sucesso!"}
            text={successText}
            type="success"
        />

        <BoxStepDesktop>
            <CenteredBox style={{ marginTop: 30 }}>
                <V3TextH1>Qual a administradora do seu consórcio?</V3TextH1>
            </CenteredBox>
            <CenteredBox style={{ marginTop: 15, padding: "0 100px" }}>
                <V3Searcheable
                    id="administradoras"
                    sx={{ borderRadius: 15 }}
                    name="administradoras"
                    action={handleChangeSelectAdm}
                    options={admSelect}
                    value={admDesc}
                    label="Administradora do Consórcio"
                    initial={recInitial}
                />
            </CenteredBox>

            <CenteredBox style={{ marginTop: 30 }}>
                <V3TextH1>Quem é o titular?</V3TextH1>
            </CenteredBox>

            <CenteredBox style={{ gap: 10, marginTop: 15 }}>
                <V3RadioButton
                    text="Pessoa Física"
                    selected={tipoPessoa === "PF"}
                    onClick={() => {
                    setTipoPessoa("PF");
                    }}
                />
                <V3RadioButton
                    text="Pessoa Jurídica"
                    selected={tipoPessoa === "PJ"}
                    onClick={() => {
                    setTipoPessoa("PJ");
                    }}
                />
            </CenteredBox>
            {tipoPessoa === "PF" && (
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        gap: 10,
                        paddingLeft: 100,
                        marginTop: 20,
                    }}
                >
                    <V3TextCustom
                        sx={{ width: 447 }}
                        size="small"
                        onChange={(e) => { handleNomeCompleto(e.target.value); }}
                        label="Nome Completo"
                        error={nomeCompletoErr}
                        helperText={nomeCompletoErr && "Preencha o seu nome completo."}
                        required
                        value={nomeCompleto}
                    />

                    <V3TextCustom
                        id="cpf"
                        sx={{ width: 447 }}
                        label="CPF"
                        onChange={(e) => { handleCpf(e.target.value); }}
                        required
                        size="small"
                        error={cpfErr}
                        helperText={cpfErr && "CPF inválido."}
                        value={cpf}
                    />

                    <V3TextCustom
                        sx={{ width: 447 }}
                        label="E-mail"
                        onChange={(e) => { handleEmail(e.target.value); }}
                        error={emailErr}
                        size="small"
                        helperText={emailErr && "E-mail inválido."}
                        value={email}
                        required
                    />

                    <V3TextCustom
                        size="small"
                        sx={{ width: 447 }}
                        onChange={(e) => { handleTelefone(e.target.value); }}
                        error={telefoneErr}
                        value={telefone}
                        label="Telefone"
                        helperText={ telefoneErr && "Telefone deve ser no formato(11) 1234-5678 ou (11) 98765-4321." }
                        required
                    />

                    <FormControlLabel
                        value="sim"
                        control={
                            <Checkbox
                                checked={isWhatsApp}
                                onChange={() => setIsWhatsApp(!isWhatsApp)}
                                value={0}
                                name="radio-buttons"
                                inputProps={{ "aria-label": "A" }}
                                sx={{ color: v3Colors.primary, "&.Mui-checked": { color: v3Colors.primary, } }}
                            />
                        }
                        label="Este telefone é WhatsApp, aceito receber contato."
                    />
                </div>
            )}
            {tipoPessoa === "PJ" && (
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        gap: 10,
                        paddingLeft: 100,
                        marginTop: 20,
                    }}
                >
                    <V3TextCustom
                        size="small"
                        sx={{ width: 447 }}
                        onChange={(e) => { handleRazaoSocial(e.target.value); }}
                        label="Razão Social"
                        value={razaoSocial}
                        error={razaoErr}
                        helperText={razaoErr && "Razão Social inválida."}
                        required
                    />

                    <V3TextCustom
                        size="small"
                        sx={{ width: 447 }}
                        label="CNPJ"
                        onChange={(e) => { handleCnpj(e.target.value); }}
                        error={cnpjErr}
                        value={cnpj}
                        helperText={cnpjErr && "CNPJ inválido."}
                        required
                    />

                    <V3TextCustom
                        sx={{ width: 447 }}
                        label="E-mail"
                        onChange={(e) => { handleEmail(e.target.value); }}
                        error={emailErr}
                        size="small"
                        value={email}
                        helperText={emailErr && "E-mail inválido."}
                        required
                    />

                    <V3TextCustom
                        size="small"
                        sx={{ width: 447 }}
                        onChange={(e) => { handleTelefone(e.target.value); }}
                        error={telefoneErr}
                        value={telefone}
                        label="Telefone"
                        helperText={ telefoneErr && "Telefone deve ser no formato(11) 1234-5678 ou (11) 98765-4321." }
                        required
                    />

                    <FormControlLabel
                        value="sim"
                        control={
                            <Checkbox
                                checked={isWhatsApp}
                                onChange={() => setIsWhatsApp(!isWhatsApp)}
                                value={0}
                                name="radio-buttons"
                                inputProps={{ "aria-label": "A" }}
                                sx={{ color: v3Colors.primary, "&.Mui-checked": { color: v3Colors.primary } }}
                            />
                        }
                        label="Este telefone é WhatsApp, aceito receber contato."
                    />
                </div>
            )}

            <div
                style={{
                    display: "flex",
                    flex: 1,
                    marginTop: 30,
                    marginBottom: 10,
                    paddingRight: 109,
                    justifyContent: "flex-end",
                }}
            >
                <V3Button
                    onClick={handleNext}
                    style={{ marginBottom: 30 }}
                    width={206}
                    text={"Enviar"}
                    estilo="primary"
                />
            </div>
        </BoxStepDesktop>
        </>
    )}

    {mobile && (
        <>
        <V3Modal
            open={hasError}
            actionClose={setHasError}
            title={"Atenção!"}
            text={errorText}
            type="error"
        />
        <V3Modal
            open={hasSuccess}
            actionClose={setHasSuccess}
            title={"Sucesso!"}
            text={successText}
            type="success"
        />
        <BoxStepMobile>
            <CenteredBoxMobile style={{ marginTop: 30 }}>
            <V3TextH1 style={{ textAlign: "center" }}>
                Qual a administradora do seu consórcio?
            </V3TextH1>
            </CenteredBoxMobile>
            <CenteredBoxMobile style={{ marginTop: 15 }}>
            <V3Searcheable
                id="administradoras"
                sx={{ borderRadius: 15 }}
                name="administradoras"
                action={handleChangeSelectAdm}
                options={admSelect}
                value={admDesc}
                label="Administradora do Consórcio"
                initial={recInitial}
            />
            </CenteredBoxMobile>

            <CenteredBoxMobile style={{ marginTop: 30 }}>
                <V3TextH1>Quem é o titular?</V3TextH1>
            </CenteredBoxMobile>

            <CenteredBoxMobile style={{ gap: 10, marginTop: 15 }}>
                <V3RadioButton
                    text="Pessoa Física"
                    selected={tipoPessoa === "PF"}
                    onClick={() => {
                    setTipoPessoa("PF");
                    }}
                />
                <V3RadioButton
                    text="Pessoa Jurídica"
                    selected={tipoPessoa === "PJ"}
                    onClick={() => {
                    setTipoPessoa("PJ");
                    }}
                />
            </CenteredBoxMobile>

            {tipoPessoa === "PF" && (
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        gap: 10,
                        paddingLeft: 15,
                        paddingRight: 15,
                        marginTop: 20,
                    }}
                >
                    <V3TextCustom
                        sx={{ width: "100%" }}
                        size="small"
                        onChange={(e) => { handleNomeCompleto(e.target.value); }}
                        label="Nome Completo"
                        error={nomeCompletoErr}
                        helperText={nomeCompletoErr && "Preencha o seu nome completo."}
                        required
                        value={nomeCompleto}
                    />

                    <V3TextCustom
                        id="cpf"
                        sx={{ width: "100%" }}
                        label="CPF"
                        onChange={(e) => { handleCpf(e.target.value); }}
                        required
                        size="small"
                        error={cpfErr}
                        helperText={cpfErr && "CPF inválido."}
                        value={cpf}
                    />

                    <V3TextCustom
                        sx={{ width: "100%" }}
                        label="E-mail"
                        onChange={(e) => { handleEmail(e.target.value); }}
                        error={emailErr}
                        size="small"
                        helperText={emailErr && "E-mail inválido."}
                        value={email}
                        type="email"
                        required
                    />

                    <V3TextCustom
                        size="small"
                        sx={{ width: "100%" }}
                        onChange={(e) => { handleTelefone(e.target.value); }}
                        error={telefoneErr}
                        value={telefone}
                        label="Telefone"
                        helperText={
                            telefoneErr &&
                            "Telefone deve ser no formato(11) 1234-5678 ou (11) 98765-4321."
                        }
                        required
                    />

                    <FormControlLabel
                        value="sim"
                        control={
                            <Checkbox
                                checked={isWhatsApp}
                                onChange={() => setIsWhatsApp(!isWhatsApp)}
                                value={0}
                                name="radio-buttons"
                                inputProps={{ "aria-label": "A" }}
                                sx={{ color: v3Colors.primary, "&.Mui-checked": { color: v3Colors.primary } }}
                            />
                        }
                        label="Este telefone é WhatsApp, aceito receber contato."
                    />
                </div>
            )}

            {tipoPessoa === "PJ" && (
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        gap: 10,
                        paddingLeft: 15,
                        paddingRight: 15,
                        marginTop: 20,
                    }}
                >
                    <V3TextCustom
                        size="small"
                        sx={{ width: "100%" }}
                        onChange={(e) => { handleRazaoSocial(e.target.value); }}
                        label="Razão Social"
                        value={razaoSocial}
                        error={razaoErr}
                        helperText={razaoErr && "Razão Social inválida."}
                        required
                    />

                    <V3TextCustom
                        size="small"
                        sx={{ width: "100%" }}
                        label="CNPJ"
                        onChange={(e) => { handleCnpj(e.target.value); }}
                        error={cnpjErr}
                        value={cnpj}
                        helperText={cnpjErr && "CNPJ inválido."}
                        required
                    />
                    
                    <V3TextCustom
                        sx={{ width: "100%" }}
                        label="E-mail"
                        onChange={(e) => { handleEmail(e.target.value); }}
                        error={emailErr}
                        size="small"
                        value={email}
                        helperText={emailErr && "E-mail inválido."}
                        required
                    />

                    <V3TextCustom
                        size="small"
                        sx={{ width: "100%" }}
                        onChange={(e) => { handleTelefone(e.target.value); }}
                        error={telefoneErr}
                        value={telefone}
                        label="Telefone"
                        helperText={ telefoneErr && "Telefone deve ser no formato(11) 1234-5678 ou (11) 98765-4321." }
                        required
                    />

                    <FormControlLabel
                        value="sim"
                        control={
                            <Checkbox
                                checked={isWhatsApp}
                                onChange={() => setIsWhatsApp(!isWhatsApp)}
                                value={0}
                                name="radio-buttons"
                                inputProps={{ "aria-label": "A" }}
                                sx={{ color: v3Colors.primary, "&.Mui-checked": { color: v3Colors.primary, } }}
                            />
                        }
                        label="Este telefone é WhatsApp, aceito receber contato."
                    />
                </div>
            )}

            <div
                style={{
                    display: "flex",
                    flex: 1,
                    marginTop: 30,
                    paddingRight: 15,
                    justifyContent: "flex-end",
                }}
            >
                <V3Button
                    onClick={handleNext}
                    style={{ display: "flex", height: 45 }}
                    text={"Enviar"}
                    size="md"
                    estilo="primary"
                />
            </div>
        </BoxStepMobile>
        </>
    )}
    </>
  );
};
