import React, { useEffect, useState } from "react";
import { ContainerDesktopOPT, ContainerMobileOPT } from "../../styles/themes/components/shared/styles";

import "../../styles/reset.css";
import ConcashLogo from "../../assets/images/logo-negative.png";

import { V3TextFooter } from "../../styles/themes/components/texts";
import moment from "moment";
import { Step1 } from "./steps/step1";
import Helmet from "react-helmet";

export const verifyDate = () => {
  const dataAtual = moment();
  const dataInformada = moment();
  const diferencaEmDias = dataAtual.diff(dataInformada, 'days');

  const maisDe7Dias = diferencaEmDias >= 7;

  const startObj = localStorage.getItem("startOnboard");
  
  if (maisDe7Dias  && startObj) {
    localStorage.clear();
    window.location.reload();
  } else {
    return true;
  }
};

const VenderConsorcio: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [vc, setVc] = useState("");
  const [etapas, setEtapas] = useState([]);
  const [recData, setRecData] = useState("");

  const mobile = window.matchMedia("(max-width:768px)").matches;

  return (
    <>
      <Helmet>
        <title>Concash - Solicite Uma Proposta | Concash</title>
        <meta
          name="description"
          content="Na Concash você vende seu consórcio online com segurança, rapidez,  transparência e com a melhor oferta do mercado"
        />
      </Helmet>
      {mobile && (
        <MobileScreen
          step={currentStep}
          setCurrentStep={setCurrentStep}
          vc={vc}
          etapas={etapas}
          recData={recData}
        />
      )}
      {!mobile && (
        <DesktopScreen
          step={currentStep}
          setCurrentStep={setCurrentStep}
          vc={vc}
          etapas={etapas}
          recData={recData}
        />
      )}
    </>
  );
};

const DesktopScreen = ({ step, vc, setCurrentStep, recData }: any) => {
  return (
    <>
      <ContainerDesktopOPT>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 10,
          }}
        >
          <img
            loading="lazy"
            src={ConcashLogo}
            style={{ marginBottom: 10 }}
            width={162}
            height={44}
            alt="concash"
          />
        </div>
        <Step1
            vc={vc}
            setCurrentStep={setCurrentStep}
            recData={recData}
        />
        <div>
          <V3TextFooter>
            © Copyright {moment(new Date()).format("YYYY")} Concash. Todos os
            direitos reservados{" "}
          </V3TextFooter>
        </div>
      </ContainerDesktopOPT>
      {/* )} */}
    </>
  );
};

const MobileScreen = ({ step, vc, setCurrentStep, recData }: any) => {
  return (
    <>
      <ContainerMobileOPT>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 40,
          }}
        >
          <img
            loading="lazy"
            src={ConcashLogo}
            style={{ marginBottom: 10 }}
            width={160}
            height={44}
            alt="concash"
          />

          <div>
            <Step1
                vc={vc}
                setCurrentStep={setCurrentStep}
                recData={recData}
            />
          </div>

          <div style={{ position: "relative", bottom: -15 }}>
            <V3TextFooter style={{ textAlign: "center" }}>
              © Copyright {moment(new Date()).format("YYYY")} Concash.
              <br />
              Todos os direitos reservados{" "}
            </V3TextFooter>
          </div>
        </div>
      </ContainerMobileOPT>
    </>
  );
};

export default VenderConsorcio;
