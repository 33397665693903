import { useState, useEffect } from "react";
import {
  BoxPinStep,
  BoxStep,
  BoxStepDesktop,
  BoxStepMobile,
  CenteredBox,
  CenteredBoxMobile,
} from "../../../styles/themes/components/box";
import {
  V3Button,
  V3RadioButton,
} from "../../../styles/themes/components/button";
import { PinStepActive } from "../../../styles/themes/components/pin";
import {
  V3Text5,
  V3TextFooter,
  V3TextH1,
} from "../../../styles/themes/components/texts";
import { v3Colors } from "../../../styles/themes/v3/colors";
import FloatButton from "../../../components/FloatButton";

import {
  ContainerDesktopOPT,
  ContainerMobileOPT,
} from "../../../styles/themes/components/shared/styles";
import ConcashLogo from "../../../assets/images/logo-negative.png"
import { BsWhatsapp } from "react-icons/bs";
import { RiPhoneLine } from "react-icons/ri";
import moment from "moment";
import TagManager from 'react-gtm-module'
export const SucessoVenderV2 = (props) => {
  const { navigtion } = props;
  const mobile = window.matchMedia("(max-width:768px)").matches;
  const whatsapp = "https://api.whatsapp.com/send/?phone=551140405686&text=Ol%C3%A1%2C+quero+vender+minha+cota+de+consorcio+para+a+Concash%21&type=phone_number&app_absent=0";
  const [email, setEmail] = useState(localStorage.getItem("emailO"));
  const [telefone, setTelefone] = useState(localStorage.getItem("telO"));
  const [idTipoConsorcio, setIdTipoConsorcio] = useState(localStorage.getItem("tipoConsorcioO"));
  const [statusCota, setStatusCota] = useState(localStorage.getItem("statusCotaO"));
  const [administradora, setAdministradora] = useState(localStorage.getItem("administradoraODesc"));
  useEffect(() => {
    const vc = localStorage.getItem("concluido");
    localStorage.clear();
  }, []);

  return (
    <>
      {mobile && (
        <>
          <ContainerMobileOPT>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 40,
              }}
            >
              <img
                loading="lazy"
                onClick={() => (window.location.href = "/")}
                src={ConcashLogo}
                style={{ marginBottom: 10 }}
                width={110}
                height={30}
                alt="concash-logo"
              />
              <V3Text5
                style={{
                  alignSelf: "center",
                  textAlign: "center",
                  marginBottom: 10,
                  padding: "10px 30px ",
                  fontWeight: "400",
                  lineHeight: 1.3,
                  fontSize: 15,
                }}
              >
                Venda seu consórcio online com segurança, rapidez e
                transparência. <br />
                Caso queira saber mais sobre a Concash{" "}
                <a href="/" style={{ color: v3Colors.grayHard }}>
                  clique aqui
                </a>
                .
              </V3Text5>
              <BoxStepMobile style={{ minHeight: 450 }}>
                <CenteredBoxMobile>
                  <V3TextH1 style={{ textAlign: "center" }}>
                    Obrigado por escolher a Concash!
                  </V3TextH1>
                </CenteredBoxMobile>
                <CenteredBoxMobile style={{ marginTop: "15px" }}>
                  <V3Text5 style={{ textAlign: "center" }}>
                    
                  Em breve um de nossos consultores irá entrar em contato com você por ligação ou WhatsApp através do número (11) 4040-5686,  para apresentar a melhor oferta para compra do seu consórcio. 
                  </V3Text5>
                </CenteredBoxMobile>
                <CenteredBoxMobile
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 15,
                    gap: 10,
                    padding: "0px 10px",
                  }}
                >
                  <V3Text5 style={{ textAlign: "center" }}>
                    
Caso tenha alguma dúvida, estamos à sua disposição nos nossos canais de atendimento, de segunda a sexta-feira, das 9h às 18h.
                  </V3Text5>
                </CenteredBoxMobile>
                <CenteredBoxMobile style={{ gap: 20, marginTop: 30 }}>
                  <div
                    onClick={() => {
                      const tagManagerArgs = {
                        gtmId: 'GTM-WB757J7'
                      }
                      var tipoConsorcio = ''
                      if (idTipoConsorcio === "1") {
                        tipoConsorcio = 'Imóvel'
                      } else if (idTipoConsorcio === "2") {
                        tipoConsorcio = 'Serviço'
                      } else if (idTipoConsorcio === "3") {
                        tipoConsorcio = 'Automóvel'
                      } else {
                        tipoConsorcio = 'Motocicleta'
                      }
                      var statusCotaO = ''
                      if (statusCota === "1") {
                        statusCotaO = 'ATIVA'
                      } else {
                        statusCotaO = 'CANCELADA'
                      }
                      TagManager.initialize(tagManagerArgs);
                      window.dataLayer.push({
                        event: "whatsapp-proposta",
                        administradora: administradora,
                        tipo: tipoConsorcio,
                        status: statusCotaO,
                        email: email,
                        phone: telefone
                      });
                      window.open(whatsapp, "_blank")
                    }
                    }
                    style={{
                      display: "flex",
                      width: 250,
                      height: 50,
                      borderRadius: 8,
                      gap: 15,
                      backgroundColor: v3Colors.primary,
                      color: v3Colors.white,
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: "Roboto",
                      fontWeight: "500",
                    }}
                  >
                    <BsWhatsapp size={24} />
                    <span>(11) 4040-5686</span>
                  </div>
                </CenteredBoxMobile>

                <CenteredBoxMobile style={{ gap: 20, marginTop: 30 }}>
                  <div
                    onClick={() => (window.location.href = "tel:+551140405686")}
                    style={{
                      display: "flex",
                      width: 250,
                      height: 50,
                      borderRadius: 8,
                      gap: 15,
                      backgroundColor: v3Colors.primary,
                      color: v3Colors.white,
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: "Roboto",
                      fontWeight: "500",
                    }}
                  >
                    <RiPhoneLine size={24} />
                    <span>(11) 4040-5686</span>
                  </div>
                </CenteredBoxMobile>
               
              </BoxStepMobile>
            </div>
            <div>
              <V3TextFooter>
                © Copyright {moment(new Date()).format("YYYY")} Concash. Todos
                os direitos reservados{" "}
              </V3TextFooter>
            </div>
          </ContainerMobileOPT>
        </>
      )}
      {!mobile && (
        <ContainerDesktopOPT>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 40,
            }}
          >
            <img
              loading="lazy"
              onClick={() => (window.location.href = "/")}
              src={ConcashLogo}
              style={{ marginBottom: 10 }}
              width={110}
              height={30}
              alt="concash-logo"
            />
            <V3Text5
              style={{
                alignSelf: "center",
                textAlign: "center",
                marginBottom: 10,
                padding: "10px 30px ",
                fontWeight: "400",
                lineHeight: 1.3,
                fontSize: 15,
              }}
            >
              Venda seu consórcio online com segurança, rapidez e transparência.
              <br /> Caso queira saber mais sobre a Concash{" "}
              <a href="/" style={{ color: v3Colors.grayHard }}>
                clique aqui
              </a>
              .
            </V3Text5>
            <BoxStepDesktop style={{ minHeight: 450 }}>
              <CenteredBoxMobile>
                <V3TextH1 style={{ textAlign: "center" }}>
                  Obrigado por escolher a Concash!
                </V3TextH1>
              </CenteredBoxMobile>
              <CenteredBoxMobile style={{ marginTop: "15px" }}>
                <V3Text5 style={{ textAlign: "center" }}>
                Em breve um de nossos consultores irá entrar em contato com você por ligação ou WhatsApp através do número (11) 4040-5686,  para apresentar a melhor oferta para compra do seu consórcio. 

                </V3Text5>
              </CenteredBoxMobile>
              <CenteredBox
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 15,
                  gap: 10,
                  padding: "0px 100px",
                }}
              >
                <V3Text5 style={{ textAlign: "center" }}>
                 
                Caso tenha alguma dúvida, estamos à sua disposição nos nossos canais de atendimento, de segunda a sexta-feira, das 9h às 18h.
                </V3Text5>
              </CenteredBox>

              <CenteredBox
                style={{
                  marginTop: 15,
                  padding: "0px 100px",
                }}
              ></CenteredBox>

              <CenteredBox style={{ gap: 20, marginTop: 30 }}>
                <div
                  onClick={() => {
                    const tagManagerArgs = {
                      gtmId: 'GTM-WB757J7'
                    }
                    var tipoConsorcio = ''
                    if (idTipoConsorcio === "1") {
                      tipoConsorcio = 'Imóvel'
                    } else if (idTipoConsorcio === "2") {
                      tipoConsorcio = 'Serviço'
                    } else if (idTipoConsorcio === "3") {
                      tipoConsorcio = 'Automóvel'
                    } else {
                      tipoConsorcio = 'Motocicleta'
                    }
                    var statusCotaO = ''
                    if (statusCota === "1") {
                      statusCotaO = 'ATIVA'
                    } else {
                      statusCotaO = 'CANCELADA'
                    }
                    TagManager.initialize(tagManagerArgs);
                    window.dataLayer.push({
                      event: "whatsapp-proposta",
                      administradora: administradora,
                      tipo: tipoConsorcio,
                      status: statusCotaO,
                      email: email,
                      phone: telefone
                    });
                    window.open(whatsapp, "_blank")
                  }
                  }
                  style={{
                    display: "flex",
                    width: 250,
                    height: 50,
                    borderRadius: 8,
                    gap: 15,
                    backgroundColor: v3Colors.primary,
                    color: v3Colors.white,
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  <BsWhatsapp size={24} />
                  <span>(11) 4040-5686</span>
                </div>
              </CenteredBox>
            </BoxStepDesktop>
          </div>

          <div style={{ position: "relative", bottom: -15 }}>
            <V3TextFooter style={{ textAlign: "center" }}>
              © Copyright {moment(new Date()).format("YYYY")} Concash.
              <br />
              Todos os direitos reservados{" "}
            </V3TextFooter>
          </div>
        </ContainerDesktopOPT>
      )}
    </>
  );
};
